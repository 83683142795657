import { useTranslation } from "react-i18next"
import { useRequiredCampaignState } from "../../helpers/campaignStates"
import FooterWinner from "../../Components/FooterWinner"
import HeaderWinner from "../../Components/Header"

export default function ComingSoon() {
    useRequiredCampaignState("comingsoon")
    const { t } = useTranslation()
    return (
        <div className="wrapper wrapper--sticky-footer">
             <HeaderWinner />
           <div className="main main--sticky-footer">
           <section className="section-form">
              <div className="shell">
                <div className="section__inner" style={{margin: "5rem auto", textAlign: "center"}}>
                <header className="section__head" >                   
                    <h1>Coming Soon</h1>
                  </header>
                  </div></div></section>
                  <div className="section__body">
                  <div className="form" >
                  
                  </div>
                  
                  </div>

           </div>
           <FooterWinner />
        </div>
    )
}