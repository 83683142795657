import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { campaignClient, setSessionClient } from "../../../api";
import FooterWinner from "../../../Components/FooterWinner";
import HeaderWinner from "../../../Components/Header";
import InputMask from "react-input-mask";
import SignaturePad from "react-signature-canvas";
import { canadianPostalCodeRegex, dateOfBirthRegex, looseNorthAmericanPhoneRegex, stringRegex } from "../../../helpers/regexes";
import "./modal.css";
import Contests from "./Contest";
import disableBrowserHistory from "../../../Components/DisableBrowserBack";
import Loading from "../../../Components/Loading";
import { b64toBlob } from "../../../helpers/utils";
import axios from "axios";
import { useQuery } from "react-query";

type registerForm = {
    firstName: string;
    lastName: string;
    address: string;
    address1?: string;
    province: string;
    city: string;
    postal: string;
    phone: string;
    dob: string;
    stq: string;
};

type PrizeClaimType = {
    error?: string;
    message?: string;
    claimed?: string;
};

declare const window: Window &
    typeof globalThis & {
        vex: any;
    };

export default function PrizeClaim() {
    disableBrowserHistory();
    const navigate = useNavigate();
    const [signImageUrl, setSignImageUrl] = useState<File>();
    const [signature, setSignature] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [showSignature, setShowSignature] = useState(false);
    const { sk, pk } = useParams();
    const signCanvas = useRef() as React.MutableRefObject<any>;

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
    } = useForm<registerForm>({
        mode: "onChange",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        sk && setSessionClient(campaignClient.recoverSession(sk));
    }, [sk, pk]);

    const { isLoading, data, error } = useQuery("verify", async () => {
        const res: { error?: string; message?: string; claimed?: boolean } = await campaignClient.call("checkClaim", { sessionKey: sk, prizeWinnerKey: pk });
        if (res?.error && res?.message) {
            window.vex.dialog.alert({
                unsafeMessage: res.message,
                callback: () => {
                    setSubmitting(false);
                    navigate("/error");
                    return;
                },
            });
        } else if (res?.error) {
            window.vex.dialog.alert({
                unsafeMessage: "Looks like something went wrong. Please try again later.",
                callback: () => {
                    setSubmitting(false);
                    navigate("/error");
                    return;
                },
            });
        } else if (res?.claimed === false) return res;
        else {
            window.vex.dialog.alert({
                unsafeMessage: `Something went wrong.`,
                callback: () => {
                    navigate("/error");
                    return;
                },
            });
        }
        return res;
    });

    if (error) {
        window.vex.dialog.alert({
            unsafeMessage: `Something went wrong.`,
            callback: () => {
                navigate("/error");
                return;
            },
        });
    }

    async function handleRegister(data: registerForm) {
        if (submitting) {
            return;
        }
        setSubmitting(true);
        const uploadKey = await saveSignature(signature);

        if (!uploadKey) {
            setSubmitting(false);
            window.vex.dialog.alert({
                unsafeMessage: "Looks like something went wrong. Please try again later.",
                callback: () => {
                    setSubmitting(false);
                    navigate("/");
                    return;
                },
            });

            return;
        }

        if (sk && uploadKey) {
            try {
                const { error, claimed }: PrizeClaimType = await campaignClient.call("prizeClaim", {
                    ...data,
                    sign: uploadKey,
                    sessionKey: sk,
                    prizeWinnerKey: pk,
                });

                if (error) {
                    window.vex.dialog.alert({
                        unsafeMessage: `Something went wrong.`,
                        callback: () => {
                            navigate("/error");
                            return;
                        },
                    });
                }
                if (claimed) {
                    navigate("/congratulate");
                }
            } catch {
                window.vex.dialog.alert({
                    unsafeMessage: "Something went wrong please try again.",
                    callback: () => {
                        navigate(`/error`);
                    },
                });
            }
        }
        setSubmitting(false);
    }

    const clear = () => {
        signCanvas.current.clear();
        setSignImageUrl(undefined);
    };

    const renderProvincesDropdown = () => {
        const provinces: any = [
            "Alberta",
            "British Columbia",
            "Manitoba",
            "New Brunswick",
            "Newfoundland and Labrador",
            "Northwest Territories",
            "Nova Scotia",
            "Nunavut",
            "Ontario",
            "Prince Edward Island",
            "Quebec",
            "Saskatchewan",
            "Yukon",
        ];
        return provinces.map(function (province: any) {
            return (
                <option value={province} key={province}>
                    {province}
                </option>
            );
        });
    };

    async function getFileUploadUrl(sessionKey: string, type: string) {
        try {
            let { uploadUrl, key } = await campaignClient.call<{
                uploadUrl: string;
                key: string;
            }>("getFileUploadUrlForUser", {
                sessionKey: sk,
                filename: "signature",
                contentType: ".jpg",
            });

            return { uploadUrl, urlKey: key };
        } catch (e) {
            setSubmitting(false);

            console.log(e);
        }
    }

    const saveSignature = async (sign: any) => {
        setSubmitting(true);
        //  setSignImageUrl(sign);

        const res = await getFileUploadUrl(sk!, typeof sign);

        // handleUpload()

        if (sign && res?.uploadUrl) {
            const block = sign.split(";");
            const contentType = block[0].split(":")[1];
            const realData = block[1].split(",")[1];
            const blob = b64toBlob(realData, contentType);
            try {
                const data = await blob.arrayBuffer();
                axios
                    .put(res.uploadUrl, data, {
                        onUploadProgress: function (progressEvent) {
                            // const percentCompleted = Math.round(
                            //   (progressEvent.loaded * 100) / progressEvent.total
                            // );
                        },
                        headers: {
                            "Content-Type": typeof blob,
                        },
                    })
                    .then(async (res) => {
                        if (res.status === 200) {
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
                setSubmitting(false);
            } catch {
                setSubmitting(false);

                console.log("error while uploading signature");
            }
            return res.urlKey;
        }
    };

    return (
        <>
            {submitting && isLoading ? (
                <Loading />
            ) : (
                <div className="wrapper wrapper--sticky-footer">
                    {showSignature && (
                        <div className="modal">
                            <div className="popup js-popup " id="popup-1">
                                <div className="popup__head">
                                    <h2>Add your signature</h2>

                                    <a className="js-close-popup popup__close" onClick={() => setShowSignature(false)}>
                                        <img src="/assets/images/svg/close.svg" alt="close_icon" />
                                    </a>
                                </div>
                                {/* /.popup__head */}

                                <div className="popup__body">
                                    <p>Please add your digital signature in the space below </p>
                                    <SignaturePad
                                        ref={signCanvas}
                                        penColor="black"
                                        canvasProps={{
                                            className: "SigPad",
                                            style: {
                                                position: "relative",
                                                display: "block",
                                                zIndex: "9999",
                                                width: "100%",
                                                minHeight: "80px",
                                                height: "150px",
                                                background: "white",
                                                border: "2px solid grey",

                                                textDecoration: "none",
                                            },
                                            onClick: () => {
                                                setSignImageUrl(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                                            },
                                            onChange: () => {
                                                setSignImageUrl(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                                            },
                                            onTouchEnd: () => {
                                                setSignImageUrl(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                                            },
                                        }}
                                    />
                                </div>
                                {/* /.popup__body */}

                                <div className="popup__actions">
                                    <a
                                        href="#"
                                        className="btn btn-outline js-close-popup"
                                        onClick={() => {
                                            clear();
                                            setShowSignature(false);
                                        }}>
                                        Cancel
                                    </a>

                                    <a className="btn btn-outline" onClick={clear}>
                                        Clear
                                    </a>

                                    <a
                                        className="btn btn-success popup__btn"
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            setShowSignature(false);
                                            setSignature(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                                        }}>
                                        Confirm
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                    <HeaderWinner />
                    <div className="main main--sticky-footer">
                        <section className="section-form">
                            <div className="shell">
                                <div className="section__inner">
                                    <header className="section__head">
                                        <h1>Please complete the form below to claim your prize</h1>
                                    </header>
                                    {/* /.section__head */}

                                    <div className="section__body">
                                        <div className="form">
                                            <form onSubmit={handleSubmit(handleRegister)}>
                                                <div className="form__hint">
                                                    <p>* Indicates required field</p>
                                                </div>
                                                {/* /.form__hint */}
                                                <div className="form__row">
                                                    <label htmlFor="first-name" className="form__label">
                                                        First name *
                                                    </label>

                                                    <div className="form__controls">
                                                        <input
                                                            className="field"
                                                            {...register("firstName", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Please enter your first name.",
                                                                },
                                                                pattern: {
                                                                    value: stringRegex,
                                                                    message: "Please enter a valid first name.",
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                    {errors.firstName && (
                                                        <p className="error-message">
                                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.firstName.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="form__row">
                                                    <label htmlFor="last-name" className="form__label">
                                                        Last name *
                                                    </label>

                                                    <div className="form__controls">
                                                        <input
                                                            className="field"
                                                            {...register("lastName", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Please enter your last name.",
                                                                },
                                                                pattern: {
                                                                    value: stringRegex,
                                                                    message: "Please enter a valid last name.",
                                                                },
                                                            })}
                                                        />
                                                    </div>

                                                    {errors.lastName && (
                                                        <p className="error-message">
                                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.lastName.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="form__row">
                                                    <label htmlFor="address-1" className="form__label">
                                                        Address line 1 *
                                                    </label>

                                                    <div className="form__controls">
                                                        <input
                                                            className="field"
                                                            {...register("address", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Please enter your address line 1.",
                                                                },
                                                            })}
                                                        />
                                                    </div>

                                                    {errors.address && (
                                                        <p className="error-message">
                                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.address.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="form__row">
                                                    <label htmlFor="address-2" className="form__label">
                                                        Address line 2
                                                    </label>

                                                    <div className="form__controls">
                                                        <input className="field" {...register("address1")} />
                                                    </div>
                                                </div>
                                                <div className="form__row">
                                                    <label htmlFor="territory" className="form__label">
                                                        Province/territory *
                                                    </label>

                                                    <div className="select">
                                                        <select
                                                            {...register("province", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Select a province/territory.",
                                                                },
                                                            })}>
                                                            <option value="">Select a province/territory</option>
                                                            {renderProvincesDropdown()}
                                                        </select>
                                                    </div>

                                                    {errors.province && (
                                                        <p className="error-message">
                                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.province.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="form__row">
                                                    <label htmlFor="address-1" className="form__label">
                                                        City *
                                                    </label>

                                                    <div className="form__controls">
                                                        <input
                                                            className="field"
                                                            {...register("city", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Please enter your city.",
                                                                },
                                                                pattern: {
                                                                    value: stringRegex,
                                                                    message: "Please enter a valid city.",
                                                                },
                                                            })}
                                                        />
                                                    </div>

                                                    {errors.city && (
                                                        <p className="error-message">
                                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.city.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="form__row">
                                                    <label htmlFor="postal-code" className="form__label">
                                                        Postal code *
                                                    </label>

                                                    <div className="form__controls">
                                                        <input
                                                            className="field"
                                                            {...register("postal", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Please enter your postal code.",
                                                                },
                                                                pattern: {
                                                                    value: canadianPostalCodeRegex,
                                                                    message: "Please enter a valid postal code.",
                                                                },
                                                            })}
                                                        />
                                                    </div>

                                                    {errors.postal && (
                                                        <p className="error-message">
                                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.postal.message}
                                                        </p>
                                                    )}
                                                </div>
                                                {/* /.form__row */}
                                                <div className="form__row">
                                                    <label htmlFor="phone" className="form__label">
                                                        Phone *
                                                    </label>

                                                    <div className="form__controls">
                                                        <input
                                                            className="field"
                                                            {...register("phone", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Please enter your phone number.",
                                                                },
                                                                pattern: {
                                                                    value: looseNorthAmericanPhoneRegex,
                                                                    message: "Please enter a valid phone number.",
                                                                },
                                                            })}
                                                        />
                                                    </div>

                                                    {errors.phone && (
                                                        <p className="error-message">
                                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.phone.message}
                                                        </p>
                                                    )}
                                                </div>
                                                {/* /.form__row */}
                                                <div className="form__row">
                                                    <label htmlFor="date" className="form__label">
                                                        Date of birth *
                                                    </label>
                                                    <div className="form__controls">
                                                        <InputMask
                                                            className="field"
                                                            {...register("dob", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Please enter your date of birth.",
                                                                },
                                                                pattern: {
                                                                    value: /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/,
                                                                    message: "Please enter valid date of birth.",
                                                                },
                                                            })}
                                                            mask="99/99/9999"
                                                            // maskChar="1"
                                                            placeholder="DD/MM/YYYY"
                                                        />
                                                        {/* <input
                                                            placeholder="(e.g. DD/MM/YYYY)"
                                                            className="field"
                                                            {...register("dob", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Please enter your date of birth.",
                                                                },
                                                                pattern: {
                                                                    value: dateOfBirthRegex,
                                                                    message: "Please enter valid your date of birth.",
                                                                },
                                                            })}
                                                        /> */}
                                                    </div>

                                                    {errors.dob && (
                                                        <p className="error-message">
                                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.dob.message}
                                                        </p>
                                                    )}
                                                </div>
                                                {/* /.form__row */}
                                                <p>
                                                    If the section below is not completed by a Canada Resident Winner, he/she will not be eligible to receive the prize. In order to
                                                    receive your prize, you must first correctly answer the Mathematical-Skill Testing Question below (unaided by electronic or
                                                    mechanical or otherwise):
                                                </p>
                                                <h3>Mathematical-Skill Testing Question:</h3>
                                                <ul>
                                                    <li>Step 1: Multiply 10x10.</li>

                                                    <li>Step 2: Divide the answer from Step 1 by 4.</li>

                                                    <li>Step 3: Add 25 to the answer from Step 2.</li>

                                                    <li>Step 4: Subtract 49 from the answer from Step 3.</li>
                                                </ul>
                                                <div className="form__row">
                                                    <label htmlFor="question" className="form__label">
                                                        Skill-testing question answer *
                                                    </label>

                                                    <div className="form__controls">
                                                        <input
                                                            className="field"
                                                            {...register("stq", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Please answer the skill-testing question.",
                                                                },
                                                                pattern: {
                                                                    value: /^[1]$/,
                                                                    message: "You must correctly answer the skill-testing question.",
                                                                },
                                                            })}
                                                        />
                                                    </div>

                                                    {errors.stq && (
                                                        <p className="error-message">
                                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.stq.message}
                                                        </p>
                                                    )}
                                                </div>
                                                {/* /.form__row */}
                                                <Contests />
                                                <a className="form__btn js-popup-btn btn btn-outline" onClick={() => setShowSignature(true)} style={{ marginTop: "20px" }}>
                                                    <span>Add my signature *</span>
                                                </a>
                                                {!signImageUrl && isDirty && (
                                                    <p className="error-message">
                                                        <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> Please add your signature.
                                                    </p>
                                                )}
                                                {signImageUrl && !showSignature && (
                                                    <div className="">
                                                        <div className="" style={{ textAlign: "right", marginBottom: "2rem" }}>
                                                            <a
                                                                onClick={() => {
                                                                    setSignImageUrl(undefined);
                                                                }}
                                                                className="js-close-popup popup__close">
                                                                <img src="/assets/images/svg/close.svg" alt="close icon" />
                                                            </a>
                                                        </div>

                                                        <div className="" style={{ marginBottom: "2rem", border: "0.1rem solid #434657", borderRadius: "0.3rem" }}>
                                                            <img src={String(signImageUrl)} alt="signature" width="100%" height="80px" />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="form__actions">
                                                    <button type="submit" className="btn-success form__btn">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        {/* /.form */}
                                    </div>
                                    {/* /.section__body */}
                                </div>
                                {/* /.section__inner */}
                            </div>
                            {/* /.shell */}
                        </section>
                    </div>
                    {/* /.main */}

                    <a style={{ textAlign: "center" }} href="https://www.walmartcanada.ca/privacy-notice" target="_blank" rel="noopener noreferrer">
                        Privacy policy
                    </a>
                    <FooterWinner />
                </div>
            )}
        </>
    );
}
