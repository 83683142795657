import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LocalStorageBackend from "i18next-localstorage-backend"

export default i18n.use(initReactI18next).init({
  resources: {
    en: { translation: require("./en.json") }
  },
  fallbackLng: "en",
  debug: process.env.REACT_APP_ENV !== "prod",
  interpolation: {
    escapeValue: false,
  },
  backend: LocalStorageBackend,
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "span"],
  },
})
