import { defaultStorageHelper } from "@spry/campaign-client"
import disableBrowserHistory from "../../../Components/DisableBrowserBack"
import FooterWinner from "../../../Components/FooterWinner"
import HeaderWinner from "../../../Components/Header"
import { useEffect } from "react"

export default function Congratulate() {
  disableBrowserHistory()
  useEffect(() => {
    defaultStorageHelper.remove("sessionKey")
  }, [])
  return (
    <div className="wrapper wrapper--sticky-footer">
      <HeaderWinner />

      <div className="main main--sticky-footer">
        <section className="section-prize">
          <div className="shell">
            <div className="section__inner">
              <figure>
                <img src="assets/images/svg/check.svg" alt="check icon" />
              </figure>

              <h1>Congratulations!</h1>

              <p>
                You have successfully claimed your prize:{" "}
                <span>
                  <strong>
                    One (1) Wal-Mart gift card in the amount of
                    CDN$1,000.
                  </strong>
                </span>
              </p>

              <p>
                Your prize will be emailed to the email address you registered with within
                the next 30 days.
              </p>

              <p>Thank you for participating!</p>
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-prize */}
      </div>
      {/* /.main */}

      <FooterWinner />
    </div>
  )
}
