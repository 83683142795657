import dayjs from "dayjs"
import { FunctionComponent } from "react"

interface FooterWinnerProps {}

const FooterWinner: FunctionComponent<FooterWinnerProps> = () => {
  return (
    <footer className="footer">
      <div className="shell">
        <p>Copyright &copy; Walmart {dayjs().year()}</p>
      </div>
      {/* /.shell */}
    </footer>
  )
}

export default FooterWinner
