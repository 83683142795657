import { FunctionComponent } from "react";

interface ContestsProps {}

const Contests: FunctionComponent<ContestsProps> = () => {
    return (
        <>
            <div className="contest" style={{ overflowY: "scroll", height: "600px" }}>
                <p>
                    I am submitting this Declaration with the understanding that it will be relied upon to determine my eligibility in the “The 2025 Walmart Canada Survey
                    Sweepstakes” (the “Promotion”) sponsored by Wal-Mart Canada Corp (the “Sponsor”).{" "}
                </p>

                <p>
                    I affirm and represent that I am at least the age of majority in my territory of residence, have reviewed, understand and fully complied with (and will continue
                    to fully comply with) the{" "}
                    <a rel="noreferrer" href="https://walmartca.qualtrics.com/jfe/form/SV_85JL2PIGdueF9ZQ?Q_Language=EN&Page=SW" target="_blank">
                        Official Rules
                    </a>{" "}
                    of the Promotion (copy enclosed), and that I have committed no fraud or deception in entering the Promotion or in claiming any prize. I further represent that I
                    am not an employee of the Sponsor or of any other entity stated in the{" "}
                    <a rel="noreferrer" href="https://walmartca.qualtrics.com/jfe/form/SV_85JL2PIGdueF9ZQ?Q_Language=EN&Page=SW" target="_blank">
                        Official Rules
                    </a>{" "}
                    whose employees are not eligible to enter the Promotion, nor am I a member of the family of any such employee.{" "}
                </p>

                <p>
                    I hereby give my consent to the Sponsor and their advertising/promotion/sweepstakes agencies for the use of my name and/or likeness, without compensation, in
                    any and all advertising and promotional materials relating to the Promotion and similar promotions, which may be offered by the Sponsor.
                </p>

                <p>
                    I understand and acknowledge and hereby, for myself, my heirs, executors and administrators, do waive and release any and all rights, claims and causes of
                    action whatsoever I may have against Sponsor, as well as their advertising/promotion/sweepstakes agencies, and the officers, employees, directors,
                    representatives, shareholders and agents of any of the foregoing entities, for any matter, cause or thing whatsoever arising out of my acceptance, possession
                    and utilization of the prize that I have won in the Promotion.{" "}
                </p>

                <p>
                    I understand and acknowledge that I am solely responsible for obtaining and paying for any and all insurance relating to my acceptance and use of the prize, if
                    insurance is applicable. I acknowledge and understand that neither the Sponsor nor any of their advertising/promotion/sweepstakes agencies have arranged for or
                    carry any insurance for my benefit or the benefit of my heirs, executors and administrators relating to my acceptance and use of the prize.
                </p>

                <p>
                    I further understand and acknowledge that the Sponsor and their advertising/promotion/sweepstakes agencies are only responsible for the prize as described in
                    the{" "}
                    <a rel="noreferrer" href="https://walmartca.qualtrics.com/jfe/form/SV_85JL2PIGdueF9ZQ?Q_Language=EN&Page=SW" target="_blank">
                        Official Rules
                    </a>
                    ; and, any and all other, unspecified costs relating to the acceptance and use of the prize are my sole responsibility.
                </p>

                <p>
                    I understand and acknowledge that my right to the above prize is non-transferable and that Sponsor, as well as their advertising/promotion/sweepstakes agencies
                    make no warranties regarding the prize.
                </p>

                <p>
                    <strong>The prize I have won is:</strong> One (1) Wal-Mart gift card in the amount of CDN $1,000.
                </p>

                <p>
                    It is understood and agreed that this is a complete RELEASE and DISCHARGE of all claims and rights of the undersigned against the aforementioned parties to be
                    released, and that no action will be taken by or on behalf of the undersigned with respect to any such rights, it being understood that this release shall be
                    binding upon the heirs, executors, and administrators of the undersigned.
                </p>

                <p>
                    I agree to return immediately upon demand to the Sponsor (or a party acting on their behalf) any prize, or the value of said prize, which has been or may be
                    awarded to me if any statement made by me in this Declaration is false.
                </p>
            </div>
        </>
    );
};

export default Contests;
