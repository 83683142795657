import { Route } from "react-router-dom"
import Closed from "../Pages/Closed"
import ComingSoon from "../Pages/ComingSoon"
import Open from "../Pages/Open"
import RedemptionOnly from "../Pages/RedemptionOnly"
import Suspended from "../Pages/Suspended"
import Version from "../Pages/Version"
import "../i18n"
import Congratulate from "../Pages/Open/Congratulate"
import Error from "../Pages/Error"
import PrizeClaim from "../Pages/Open/PrizeClaim"
export default (
  <>
    <Route path="/" element={<Open />}>      
      <Route path=":sk/:pk" element={<PrizeClaim/>} />
      <Route path="error" element={<Error />} />
    </Route>
  
    <Route path="congratulate" element={<Congratulate />} />
    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<ComingSoon />} />
    <Route path="redemption" element={<RedemptionOnly />} />
    <Route path="suspended" element={<Suspended />} />
    <Route path="spry/version" element={<Version />} />
  </>
)
